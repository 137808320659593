
import { defineComponent } from "vue";
import NotFound from "./pages/NotFound.vue";
import BackgroundCanvas from "./components/BackgroundCanvas.vue";
import IKEP from "./components/IKEP.vue";
import MenuBar from "./components/MenuBar.vue";
import PageBottom from "./components/PageBottom.vue";

export default defineComponent({
  components: {
    NotFound,
    BackgroundCanvas,
    IKEP, // 毎回レンダリングするのを防ぐためにリロードされない限りは同じレンダリング結果を使う
    MenuBar,
    PageBottom,
  },
  data() {
    return {
      isNotFound: false,
      isIndexPage: false,
    };
  },
  setup() {
    const isBackgroundMove = () => {
      const isbackParam = location.search.includes("back");
      const isMobileDevice = navigator.userAgent.match(
        /(iPhone|iPad|iPod|Android.*Mobile)/i
      );
      const isBackgroundMove = isbackParam
        ? location.search.includes("back=on")
        : !isMobileDevice;
      return isBackgroundMove;
    };

    return { isBackgroundMove: isBackgroundMove() };
  },
  watch: {
    $route(newVal) {
      this.isNotFound = false;
      if (
        newVal.path === "/" ||
        newVal.path === "/en" ||
        newVal.path === "/en/"
      ) {
        this.isIndexPage = true;
        return;
      }
      this.isIndexPage = false;
    },
  },
});

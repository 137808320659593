
import * as THREE from "three";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    isLogoMove: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      animFlag: 1,
    };
  },
  methods: {
    canvasClick() {
      this.animFlag = 0;
    },
  },
  mounted() {
    const del_rot = 0.2;
    let rot = -del_rot;
    let pos = 0;

    const canvas = document.getElementById("logoCanvas") as HTMLCanvasElement;
    if (!canvas) return;
    const renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      alpha: true,
      preserveDrawingBuffer: true,
    });
    const scene = new THREE.Scene();

    const directionalLight = new THREE.DirectionalLight(0x999999);
    directionalLight.position.set(0, 0, 1);
    scene.add(directionalLight);

    const camera = new THREE.PerspectiveCamera(45, 1.0, 0.1, 3000);
    camera.position.set(0, 0, +1000);

    const update = () => {
      logoAnim();
      renderer.render(scene, camera);
      requestAnimationFrame(update);
    };

    const onResize = () => {
      const canvasParendNode = (canvas as Node).parentNode?.ELEMENT_NODE;
      if (!canvasParendNode) return;
      const width = window.innerWidth * 0.9;
      const height = width * 0.35;

      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(width, height);

      camera.aspect = width / height;
      camera.updateProjectionMatrix();
    };

    const createParticel = () => {
      for (var i = 0; i < 8; i++) {
        var sprite = new THREE.Sprite(material);
        sprite.scale.set(50, 50, 1);
        if (i % 4 == 0) {
          sprite.position.set(
            -700 + Math.random() * 500 - 250,
            -100 + Math.random() * 500 - 250,
            0
          );
        } else if (i % 4 == 1) {
          sprite.position.set(
            -350 + Math.random() * 500 - 250,
            -100 + Math.random() * 500 - 250,
            0
          );
        } else if (i % 4 == 2) {
          sprite.position.set(
            150 + Math.random() * 500 - 250,
            -100 + Math.random() * 500 - 250,
            0
          );
        } else if (i % 4 == 3) {
          sprite.position.set(
            700 + Math.random() * 500 - 250,
            -100 + Math.random() * 500 - 250,
            0
          );
        }
        particle_group.add(sprite);
        scene.add(particle_group);
      }
    };

    const logoAnim = () => {
      if (this.animFlag == 0 || this.animFlag == 1) {
        if (this.animFlag == 0) {
          var random_material = new THREE.MeshToonMaterial({
            color: Math.random() * 0xffffff,
          });
          i_top.material = random_material;
          i_mid.material = random_material;
          i_bottom.material = random_material;
          random_material = new THREE.MeshToonMaterial({
            color: Math.random() * 0xffffff,
          });
          k1.material = random_material;
          k2.material = random_material;
          k3.material = random_material;
          random_material = new THREE.MeshToonMaterial({
            color: Math.random() * 0xffffff,
          });
          e1.material = random_material;
          e2.material = random_material;
          e3.material = random_material;
          e4.material = random_material;
          random_material = new THREE.MeshToonMaterial({
            color: Math.random() * 0xffffff,
          });
          p1.material = random_material;
          p2.material = random_material;
          this.animFlag = 1;
        }
        createParticel();
        particle_group.position.y += 10;

        rot += del_rot;
        i_group.rotation.set(i_group.rotation.x, rot, i_group.rotation.z);
        k_group.rotation.set(k_group.rotation.x, rot, k_group.rotation.z);
        e_group.rotation.set(e_group.rotation.x, rot, e_group.rotation.z);
        p_group.rotation.set(p_group.rotation.x, rot, p_group.rotation.z);

        var del_pos = 0;
        if (pos < 315) {
          del_pos = (315 * del_rot) / Math.PI;
          pos += del_pos;
        } else {
          del_pos = (-315 * del_rot) / Math.PI;
          pos -= del_pos;
        }
        i_group.position.set(
          i_group.position.x,
          i_group.position.y + del_pos,
          i_group.position.z
        );
        k_group.position.set(
          k_group.position.x,
          k_group.position.y + del_pos,
          k_group.position.z
        );
        e_group.position.set(
          e_group.position.x,
          e_group.position.y + del_pos,
          e_group.position.z
        );
        p_group.position.set(
          p_group.position.x,
          p_group.position.y + del_pos,
          p_group.position.z
        );

        if (rot > Math.PI * 2) {
          scene.remove(particle_group);
          for (var i = 0; i < particle_group.children.length; i++) {
            scene.remove(particle_group.children[i]);
            delete particle_group.children[i];
          }
          particle_group = new THREE.Group();
          particle_group.position.y = 0;

          this.animFlag = 2;
          rot = 0;
          pos = 0;
          i_group.rotation.set(i_group.rotation.x, 0, i_group.rotation.z);
          k_group.rotation.set(k_group.rotation.x, 0, k_group.rotation.z);
          e_group.rotation.set(e_group.rotation.x, 0, e_group.rotation.z);
          p_group.rotation.set(p_group.rotation.x, 0, p_group.rotation.z);
          i_group.position.set(i_group.position.x, 0, i_group.position.z);
          k_group.position.set(k_group.position.x, 0, k_group.position.z);
          e_group.position.set(e_group.position.x, 0, e_group.position.z);
          p_group.position.set(p_group.position.x, 0, p_group.position.z);
        }
      }
    };

    const i_material = new THREE.MeshToonMaterial({
      color: Math.random() * 0xffffff,
    });
    const i_top_geometry = new THREE.BoxGeometry(300, 100, 100);
    const i_top = new THREE.Mesh(i_top_geometry, i_material);
    i_top.position.set(0, 250, 0);
    const i_mid_geometry = new THREE.BoxGeometry(100, 600, 100);
    const i_mid = new THREE.Mesh(i_mid_geometry, i_material);
    const i_bottom_geometry = new THREE.BoxGeometry(300, 100, 100);
    const i_bottom = new THREE.Mesh(i_bottom_geometry, i_material);
    i_bottom.position.set(0, -250, 0);
    var i_group = new THREE.Group();
    i_group.add(i_top);
    i_group.add(i_mid);
    i_group.add(i_bottom);
    i_group.position.set(-650, 0, 0);
    scene.add(i_group);

    const k_material = new THREE.MeshToonMaterial({
      color: Math.random() * 0xffffff,
    });
    const k1_geometry = new THREE.BoxGeometry(425, 100, 100);
    const k1 = new THREE.Mesh(k1_geometry, k_material);
    k1.rotation.set(0, 0, Math.PI / 4);
    k1.position.set(225 / Math.sqrt(2) - 70, 225 / Math.sqrt(2) - 50, 0);
    const k2_geometry = new THREE.BoxGeometry(100, 600, 100);
    const k2 = new THREE.Mesh(k2_geometry, k_material);
    k2.position.set(-70, 0, 0);
    const k3_geometry = new THREE.BoxGeometry(375, 100, 100);
    const k3 = new THREE.Mesh(k3_geometry, k_material);
    k3.rotation.set(0, 0, -Math.PI / 3.5);
    k3.position.set(200 / Math.sqrt(2) + 50 - 70, -200 / Math.sqrt(2), 0);
    var k_group = new THREE.Group();
    k_group.add(k1);
    k_group.add(k2);
    k_group.add(k3);
    k_group.position.set(-300, 0, 0);
    scene.add(k_group);

    const e_material = new THREE.MeshToonMaterial({
      color: Math.random() * 0xffffff,
    });
    const e1_geometry = new THREE.BoxGeometry(100, 600, 100);
    const e1 = new THREE.Mesh(e1_geometry, e_material);
    const e2_geometry = new THREE.BoxGeometry(300, 100, 100);
    const e2 = new THREE.Mesh(e2_geometry, e_material);
    e2.position.set(150, 300 - 50, 0);
    const e3_geometry = new THREE.BoxGeometry(300, 100, 100);
    const e3 = new THREE.Mesh(e3_geometry, e_material);
    e3.position.set(150, 0, 0);
    const e4_geometry = new THREE.BoxGeometry(300, 100, 100);
    const e4 = new THREE.Mesh(e4_geometry, e_material);
    e4.position.set(150, -300 + 50, 0);
    var e_group = new THREE.Group();
    e_group.add(e1);
    e_group.add(e2);
    e_group.add(e3);
    e_group.add(e4);
    e_group.position.set(100, 0, 0);
    scene.add(e_group);

    const p_material = new THREE.MeshToonMaterial({
      color: Math.random() * 0xffffff,
    });
    const p1_geometry = new THREE.BoxGeometry(100, 600, 100);
    const p1 = new THREE.Mesh(p1_geometry, p_material);
    const p2_geometry = new THREE.TorusGeometry(170, 50, 16, 100, Math.PI);
    const p2 = new THREE.Mesh(p2_geometry, p_material);
    p2.rotation.set(0, 0, -Math.PI / 2);
    p2.position.set(50, 90, 0);
    var p_group = new THREE.Group();
    p_group.add(p1);
    p_group.add(p2);
    p_group.position.set(550, 0, 0);
    scene.add(p_group);

    var particle_group = new THREE.Group();
    const spriteMap = new THREE.TextureLoader().load(
      "/images/particleImage.png"
    );
    const material = new THREE.SpriteMaterial({
      color: 0x007eff,
      map: spriteMap,
      transparent: true,
      blending: THREE.AdditiveBlending,
    });
    scene.add(particle_group);

    onResize();
    if (this.isLogoMove) {
      update();
    } else {
      renderer.render(scene, camera);
    }
    window.addEventListener("resize", onResize);
  },
});

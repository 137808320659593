<template>
  <div class="homeBottom">
    <ul>
      <li>&copy; {{ new Date().getFullYear() }} IKEP</li>
    </ul>
  </div>
</template>

<style>
.homeBottom {
  font-size: 120%;
  width: 100%;
  height: 80px;
  margin: 0;
}

.homeBottom ul {
  padding-right: 5%;
  margin: 0;
  list-style-type: none;
}

.homeBottom ul li {
  float: right;
  margin-left: 5%;
  color: white;
}

@media screen and (max-aspect-ratio: 16/10) {
  .homeBottom {
    font-size: 150%;
    width: 100%;
    height: 80px;
    margin: 0;
  }
}

@media screen and (max-aspect-ratio: 5/7) {
  .homeBottom {
    font-size: 180%;
    width: 100%;
    height: 80px;
    margin: 0;
  }
}
</style>

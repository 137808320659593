<template>
  <div class="explainBlock">
    <h1>Eye Break Timer</h1>
    <img src="/images/eye_timer.png" />
  </div>

  <div class="explainBlock">
    <h1>What's this?</h1>
    <div>
      {{ $t("message.EyeBreakTimerWhat1") }}
    </div>
    <div>
      {{ $t("message.EyeBreakTimerWhat2") }}
    </div>
    <div>{{ $t("message.EyeBreakTimerWhat3") }}</div>
    <div>
      {{ $t("message.EyeBreakTimerWhat4") }}
    </div>
    <div>
      {{ $t("message.EyeBreakTimerWhat5") }}
    </div>
  </div>

  <div class="explainBlock">
    <h1>Development</h1>
    <div>Programming Language</div>
    <ul>
      <li>Swift, C++</li>
    </ul>
    <div>Software</div>
    <ul>
      <li>Xcode, Visual Studio</li>
    </ul>
  </div>

  <div class="explainBlock">
    <h1>Release</h1>
    <div>2017/10/25</div>
  </div>

  <div class="explainBlock">
    <h1>Download URL</h1>
    <div class="appDownloadButton">
      <a
        href="https://itunes.apple.com/jp/app/eye-break-timer/id1299583418?mt=12"
      >
        <img src="/images/DownloadOnMacStore.svg" />
      </a>
    </div>
    <ul>
      <li>
        <a
          href="https://github.com/creativeIKEP/EyeBreakTimer_Windows"
          target="_blank"
          >Windows version</a
        >
      </li>
    </ul>
  </div>

  <div class="explainBlock">
    <h1>Articles</h1>
    <ul>
      <li>
        <a href="https://hikaku-choice.com/?p=9911" target="_blank"
          >Macで眼精疲労・疲れ目をを軽減・対策する設定方法</a
        >
      </li>
    </ul>
  </div>

  <div class="explainBlock">
    <h1>More Details</h1>
    <ul>
      <li>
        <a href="https://ikep.hatenablog.com/entry/2018/08/01/231802"
          >My blog</a
        >
      </li>
    </ul>
  </div>
</template>


import * as THREE from "three";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    isBackgroundMove: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    const canvas = document.getElementById("backCanvas") as HTMLCanvasElement;
    const canvasImg = document.getElementById(
      "backCanvasImg"
    ) as HTMLImageElement;
    if (!canvas || !canvasImg) return;

    let rot = 0;
    const SIZE = 1000;
    const LENGTH = 1000;
    const renderer = new THREE.WebGLRenderer({ canvas: canvas });
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(45, 1.0, 0.1, SIZE * 5);

    const initRender = () => {
      camera.position.set(0, 0, SIZE);

      for (var i = 0; i < LENGTH; i++) {
        const v = new THREE.Vector3(
          Math.random() * SIZE - SIZE / 2,
          Math.random() * SIZE - SIZE / 2,
          Math.random() * SIZE - SIZE / 2
        );
        const v0 = new THREE.Vector3(
          Math.random() * 100 - 50,
          Math.random() * 100 - 50,
          Math.random() * 100 - 50
        );
        const v1 = new THREE.Vector3(
          Math.random() * 100 - 50,
          Math.random() * 100 - 50,
          Math.random() * 100 - 50
        );
        const v2 = new THREE.Vector3(
          Math.random() * 100 - 50,
          Math.random() * 100 - 50,
          Math.random() * 100 - 50
        );
        v0.add(v);
        v1.add(v);
        v2.add(v);
        v0.toArray;
        const vertices = new Float32Array([
          v0.x,
          v0.y,
          v0.z,
          v1.x,
          v1.y,
          v1.z,
          v2.x,
          v2.y,
          v2.z,
        ]);

        const geometry = new THREE.BufferGeometry();
        geometry.setAttribute(
          "position",
          new THREE.BufferAttribute(vertices, 3)
        );
        const material = new THREE.MeshBasicMaterial({
          side: THREE.DoubleSide,
          wireframe: true,
          color: new THREE.Color(Math.random() * 0xffffff),
        });
        const mesh = new THREE.Mesh(geometry, material);
        mesh.scale.set(2, 2, 2);
        scene.add(mesh);
      }
      updateRender();
    };

    const updateRender = () => {
      const radian = (rot * Math.PI) / 180;
      camera.position.x = SIZE * Math.sin(radian);
      camera.position.z = SIZE * Math.cos(radian);
      camera.position.y = (SIZE * (Math.cos(radian) + Math.sin(radian))) / 2;
      camera.lookAt(new THREE.Vector3(0, 0, 0));
      renderer.render(scene, camera);
    };

    const update = () => {
      rot += 0.05;
      updateRender();
      requestAnimationFrame(update);
      return;
    };

    const onResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      updateRender();
    };

    const switchBackgroundMoveByQuery = () => {
      if (this.isBackgroundMove) {
        canvasImg.remove();
        update();
        window.addEventListener("resize", onResize);
        return;
      }
      const imageUrl = canvas.toDataURL();
      canvasImg.src = imageUrl;
      canvas.remove();
    };

    initRender();
    onResize();
    switchBackgroundMoveByQuery();
  },
});

<template>
  <div class="explainBlock">
    <h1>Treadraw</h1>
    <img src="/images/Treadraw.jpg" />
  </div>

  <div class="explainBlock">
    <h1>What's this?</h1>
    <div>{{ $t("message.TreadrawWhat1") }}</div>
    <div>{{ $t("message.TreadrawWhat2") }}</div>
    <div>
      {{ $t("message.TreadrawWhat3") }}
    </div>
    <div>
      {{ $t("message.TreadrawWhat4") }}
    </div>
    <div>
      {{ $t("message.TreadrawWhat5") }}
    </div>
    <div>{{ $t("message.TreadrawWhat6") }}</div>
  </div>

  <div class="explainBlock">
    <h1>Demo</h1>
    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/1Xhtt-9s1-c"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>

  <div class="explainBlock">
    <h1>Development</h1>
    <div>Programming Language</div>
    <ul>
      <li>C#</li>
      <li>Swift</li>
      <li>Objective-C</li>
      <li>Python</li>
      <li>HTML/CSS</li>
      <li>JavaScript</li>
    </ul>
    <div>Software</div>
    <ul>
      <li>Unity</li>
      <li>Xcode</li>
    </ul>
    <div>Platform&Others</div>
    <ul>
      <li>Google Cloud Platform</li>
      <li>Firebase</li>
      <li>Apple ARKit</li>
      <li>Django</li>
    </ul>
  </div>

  <div class="explainBlock">
    <h1>Prize</h1>
    <ul>
      <li>
        <a href="https://u22procon.com/2019/report/" target="_blank">
          {{ $t("message.U22ProgramingContest") }}
        </a>
        {{ $t("message.Best40") }}
      </li>
    </ul>
  </div>

  <div class="explainBlock">
    <h1>Presentation</h1>
    <ul>
      <li>
        <a href="https://talent.supporterz.jp/geekten/2020/" target="_blank">
          {{ $t("message.GEEK_TEN") }}
        </a>
      </li>
    </ul>
  </div>

  <div class="explainBlock">
    <h1>Release</h1>
    <div>2019/12/23</div>
  </div>

  <div class="explainBlock">
    <h1>Download URL</h1>
    <div class="appDownloadButton">
      <a href="https://apps.apple.com/us/app/treadraw/id1480090692?l=ja&ls=1">
        <img src="/images/DownloadOnAppStore.svg" />
      </a>
    </div>
  </div>

  <div class="explainBlock">
    <h1>More Details</h1>
    <ul>
      <li>
        <a href="https://ikep.hatenablog.com/entry/2020/01/27/103947"
          >My blog</a
        >
      </li>
    </ul>
  </div>
</template>


import { defineComponent } from "vue";
import { productsList } from "../viewDatas";
import routerPath from "../routerPath";

export default defineComponent({
  props: {
    displayProductCount: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      products: productsList(),
    };
  },
  methods: {
    isExternalUrl: routerPath.isExternalUrl,
    routerPath: routerPath.routerPath,
  },
});

const isExternalUrl = (path: string) => {
  try {
    const url = new URL(path);
    if (url.hostname !== location.host) {
      return true;
    }
  } catch {
    return false;
  }
  return false;
};

// 英語サイト、クエリ転送をするために、全ページ遷移先URL生成をここで行う。
const routerPath = (path: string) => {
  if (isExternalUrl(path)) {
    return path;
  }

  let langPath = "";
  if (location.pathname.match("^/en")) {
    langPath = "/en";
  }
  return `${langPath}${path}${location.search}`;
};

export default { isExternalUrl, routerPath };

<template>
  <div class="explainBlock">
    <h1>I AM MAN</h1>
    <div id="twoColImg">
      <li>
        <ul>
          <img src="/images/i_am_man4.png" />
        </ul>
        <ul>
          <img src="/images/i_am_man5.png" />
        </ul>
        <ul>
          <img src="/images/i_am_man3.png" />
        </ul>
        <ul>
          <img src="/images/i_am_man2.jpg" />
        </ul>
        <ul>
          <img src="/images/i_am_man1.jpg" />
        </ul>
        <ul>
          <img src="/images/i_am_man6.png" />
        </ul>
      </li>
    </div>
  </div>

  <div class="explainBlock">
    <h1>What's this?</h1>
    <div>
      {{ $t("message.I_AM_MAN_What1") }}
    </div>
    <div>
      {{ $t("message.I_AM_MAN_What2") }}
    </div>
    <div>
      {{ $t("message.I_AM_MAN_What3") }}
    </div>
    <div>
      {{ $t("message.I_AM_MAN_What4") }}
    </div>
  </div>

  <div class="explainBlock">
    <h1>How Story?</h1>
    <div>{{ $t("message.I_AM_MAN_History1") }}</div>
    <div>
      {{ $t("message.I_AM_MAN_History2") }}
    </div>
    <div>
      {{ $t("message.I_AM_MAN_History3") }}
    </div>
    <div>{{ $t("message.I_AM_MAN_History4") }}</div>
    <div>
      {{ $t("message.I_AM_MAN_History5") }}
    </div>
    <div>
      {{ $t("message.I_AM_MAN_History6") }}
    </div>
    <div>{{ $t("message.I_AM_MAN_History7") }}</div>
    <div>
      {{ $t("message.I_AM_MAN_History8") }}
    </div>
    <div>
      {{ $t("message.I_AM_MAN_History9") }}
    </div>
  </div>

  <div class="explainBlock">
    <h1>Demo</h1>
    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/22pgXQ6UTfk"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
    <br />
    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/vNUVqmGu_7w"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
    <br />
    <div>{{ $t("message.I_AM_MAN_Comment") }}</div>
  </div>

  <div class="explainBlock">
    <h1>Development</h1>
    <div>Programming Language</div>
    <ul>
      <li>C#</li>
    </ul>
    <div>SoftWare</div>
    <ul>
      <li>Unity</li>
      <li>Blender</li>
      <li>SQLite</li>
    </ul>
    <div>Platform&Others</div>
    <ul>
      <li>Arduino</li>
      <li>HTC Vive</li>
      <li>Oculus Quest2 (Oculus Link)</li>
      <li>Unlimited Hand</li>
    </ul>
  </div>

  <div class="explainBlock">
    <h1>Source Code</h1>
    <a href="https://github.com/creativeIKEP/I_AM_MAN" target="_blank">
      GitHub
    </a>
  </div>

  <div class="explainBlock">
    <h1>Exhibition</h1>
    <ul>
      <li>{{ $t("message.I_AM_MAN_Event") }}</li>
      <li>
        <a href="https://kc-i.jp/activity/award/isca/2018/" target="_blank"
          >INTERNATIONAL STUDENTS CREATIVE AWARD 2018</a
        >
      </li>
    </ul>
  </div>

  <div class="explainBlock">
    <h1>Prize</h1>
    <ul>
      <li>
        <a
          href="https://kc-i.jp/activity/award/isca/2018/winners/"
          target="_blank"
          >INTERNATIONAL STUDENTS CREATIVE AWARD 2018</a
        >
        {{ $t("message.2ndPrize") }}
      </li>
    </ul>
  </div>

  <div class="explainBlock">
    <h1>Created Day</h1>
    <div>2018/11</div>
  </div>

  <div class="explainBlock">
    <h1>More Details</h1>
    <ul>
      <li>
        <a href="https://ikep.hatenablog.com/entry/2018/12/15/141437"
          >My blog</a
        >
      </li>
    </ul>
  </div>
</template>

<template>
  <div class="explainBlock">
    <h1>{{ $t("message.shineKaguya") }}</h1>
    <div id="twoColImg">
      <li>
        <ul>
          <img src="/images/shineKaguya_1.jpg" />
        </ul>
        <ul>
          <img src="/images/shineKaguya_3.jpg" />
        </ul>
        <ul>
          <img src="/images/shineKaguya_2.png" />
        </ul>
      </li>
    </div>
  </div>

  <div class="explainBlock">
    <h1>What's this?</h1>
    <div>{{ $t("message.shineKaguyaWhat1") }}</div>
    <div>{{ $t("message.shineKaguyaWhat2") }}</div>
    <div>{{ $t("message.shineKaguyaWhat3") }}</div>
    <div>{{ $t("message.shineKaguyaWhat4") }}</div>
  </div>

  <div class="explainBlock">
    <h1>How Story?</h1>
    <div>{{ $t("message.shineKaguyaStory1") }}</div>
    <div>
      {{ $t("message.shineKaguyaStory2") }}
    </div>
    <div>{{ $t("message.shineKaguyaStory3") }}</div>
    <div>{{ $t("message.shineKaguyaStory4") }}</div>
    <div>{{ $t("message.shineKaguyaStory5") }}</div>
    <div>{{ $t("message.shineKaguyaStory6") }}</div>
  </div>

  <div class="explainBlock">
    <h1>Demo</h1>
    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/aMr8SQYSCio"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>

  <div class="explainBlock">
    <h1>Development</h1>
    <div>Programming Language</div>
    <ul>
      <li>C#</li>
    </ul>
    <div>SoftWare</div>
    <ul>
      <li>Unity</li>
      <li>Blender</li>
    </ul>
    <div>Platform&Others</div>
    <ul>
      <li>Arduino</li>
      <li>HTC Vive</li>
      <li>Oculus Quest2 (Oculus Link)</li>
    </ul>
  </div>

  <div class="explainBlock">
    <h1>Source Code</h1>
    <a
      href="https://github.com/creativeIKEP/ShineKaguyaWorldVR"
      target="_blank"
    >
      GitHub
    </a>
  </div>

  <div class="explainBlock">
    <h1>Exhibition</h1>
    <ul>
      <li>
        <a href="http://ivrc.net/2017/" target="_blank">
          {{ $t("message.IVRC2017") }}
        </a>
        <div>
          {{ $t("message.shineKaguraExhibition") }}
        </div>
      </li>
    </ul>
  </div>

  <div class="explainBlock">
    <h1>Created Day</h1>
    <div>2017/10</div>
  </div>

  <div class="explainBlock">
    <h1>Articles</h1>
    <ul>
      <li>
        <a
          href="http://www.sankeibiz.jp/business/news/171122/bsj1711222353006-n2.htm"
          target="_blank"
          >SankeiBiz</a
        >
      </li>
    </ul>
  </div>

  <div class="explainBlock">
    <h1>More Details</h1>
    <ul>
      <li>
        <a href="https://ikep.hatenablog.com/entry/2018/04/22/220314"
          >My blog</a
        >
      </li>
    </ul>
  </div>
</template>

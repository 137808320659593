import { createRouter, createWebHistory } from "vue-router";
import Index from "./pages/index.vue";
import About from "./pages/about.vue";
import Product from "./pages/product.vue";
import Products from "./pages/products.vue";
import NotFound from "./pages/NotFound.vue";

const routes = [
  { path: "/", component: Index },
  { path: "/about", component: About },
  { path: "/product", component: Product },
  { path: "/products/:product_name", component: Products },
  { path: "/en", component: Index },
  { path: "/en/about", component: About },
  { path: "/en/product", component: Product },
  { path: "/en/products/:product_name", component: Products },
  { path: "/:not_found(.*)", component: NotFound },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;

<template>
  <div class="explainBlock">
    <h1>Super AR Cart</h1>
    <img src="/images/superARCart.png" />
  </div>

  <div class="explainBlock">
    <h1>What's this?</h1>
    <div>
      {{ $t("message.SuperArCartWhat1") }}
    </div>
    <div>{{ $t("message.SuperArCartWhat2") }}</div>
    <div>
      {{ $t("message.SuperArCartWhat3") }}
    </div>
    <div>
      {{ $t("message.SuperArCartWhat4") }}
    </div>
    <div>
      {{ $t("message.SuperArCartWhat5") }}
    </div>
  </div>

  <div class="explainBlock">
    <h1>Demo</h1>
    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/rVevJCeUopE"
      frameborder="0"
      allow="autoplay; encrypted-media"
      allowfullscreen
    ></iframe>
  </div>

  <div class="explainBlock">
    <h1>Development</h1>
    <div>Programming Language</div>
    <ul>
      <li>Java</li>
    </ul>
    <div>SoftWare</div>
    <ul>
      <li>Processing</li>
    </ul>
    <div>Platform&Others</div>
    <ul>
      <li>OpenCV</li>
      <li>ARToolKit</li>
    </ul>
  </div>

  <div class="explainBlock">
    <h1>Source Code</h1>
    <a href="https://github.com/creativeIKEP/SuperARCart" target="_blank">
      GitHub
    </a>
  </div>

  <div class="explainBlock">
    <h1>Created Day</h1>
    <div>2018/7</div>
  </div>

  <div class="explainBlock">
    <h1>More Details</h1>
    <ul>
      <li>
        <a href="https://ikep.hatenablog.com/entry/2018/07/01/132301"
          >My blog</a
        >
      </li>
    </ul>
  </div>
</template>

<template>
  <div class="explainBlock">
    <h1>DX GUN GALE</h1>
    <div id="twoColImg">
      <li>
        <ul>
          <img src="/images/DX_GUN_GALE2.png" />
        </ul>
        <ul>
          <img src="/images/DX_GUN_GALE3.png" />
        </ul>
        <ul>
          <img src="/images/DX_GUN_GALE4.png" />
        </ul>
        <ul>
          <img src="/images/DX_GUN_GALE5.png" />
        </ul>
      </li>
    </div>
  </div>

  <div class="explainBlock">
    <h1>What's this?</h1>
    <div>{{ $t("message.DxGunGaleWhat1") }}</div>
    <div>{{ $t("message.DxGunGaleWhat2") }}</div>
    <br />
    <div>{{ $t("message.DxGunGaleWhat3") }}</div>
    <div>
      {{ $t("message.DxGunGaleWhat4") }}
    </div>
    <div>{{ $t("message.DxGunGaleWhat5") }}</div>
  </div>

  <div class="explainBlock">
    <h1>Demo</h1>
    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/sinVUrnLABY"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>

  <div class="explainBlock">
    <h1>Development</h1>
    <div>Programming Language</div>
    <ul>
      <li>C++</li>
    </ul>
    <div>Platform&Others</div>
    <ul>
      <li>DirectX</li>
      <li>{{ $t("message.DxLibrary") }}</li>
    </ul>
  </div>

  <div class="explainBlock">
    <h1>Source Code</h1>
    <a href="https://github.com/creativeIKEP/DirectX_Game" target="_blank">
      GitHub
    </a>
  </div>

  <div class="explainBlock">
    <h1>Created Day</h1>
    <div>2019/01</div>
  </div>

  <div class="explainBlock">
    <h1>More Details</h1>
    <ul>
      <li>
        <a
          href="https://dxlib.xsrv.jp/cgi/patio/read.cgi?no=239"
          target="_blank"
          >{{ $t("message.DxLibraryIntroPage") }}</a
        >
      </li>
    </ul>
  </div>
</template>

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "homeProduct" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products.slice(0, _ctx.displayProductCount), (product) => {
        return (_openBlock(), _createElementBlock("li", { key: product }, [
          (_ctx.isExternalUrl(product.url))
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                href: _ctx.routerPath(product.url)
              }, [
                _createElementVNode("img", {
                  src: product.imagePath
                }, null, 8, _hoisted_3),
                _createElementVNode("p", null, _toDisplayString(_ctx.$t(`message.${product.nameKey}`)), 1)
              ], 8, _hoisted_2))
            : (_openBlock(), _createBlock(_component_router_link, {
                key: 1,
                to: _ctx.routerPath(product.url)
              }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    src: product.imagePath
                  }, null, 8, _hoisted_4),
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t(`message.${product.nameKey}`)), 1)
                ]),
                _: 2
              }, 1032, ["to"]))
        ]))
      }), 128))
    ])
  ]))
}
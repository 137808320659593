<template>
  <div class="explainBlock">
    <h1>Binary Calcurator</h1>
    <img src="/images/binary_calc.png" />
  </div>

  <div class="explainBlock">
    <h1>What's this?</h1>
    <div>
      {{ $t("message.BinaryCalculatorWhat1") }}
    </div>
    <div>
      {{ $t("message.BinaryCalculatorWhat2") }}
    </div>
    <div>
      {{ $t("message.BinaryCalculatorWhat3") }}
    </div>
    <div>
      {{ $t("message.BinaryCalculatorWhat4") }}
    </div>
  </div>

  <div class="explainBlock">
    <h1>Development</h1>
    <div>Programming Language</div>
    <ul>
      <li>Swift</li>
    </ul>
    <div>Software</div>
    <ul>
      <li>Xcode</li>
    </ul>
  </div>

  <div class="explainBlock">
    <h1>Release</h1>
    <div>2017/10/24</div>
  </div>

  <div class="explainBlock">
    <h1>Download URL</h1>
    <div class="appDownloadButton">
      <a
        href="https://itunes.apple.com/jp/app/binary-calculator/id1299601504?mt=8"
      >
        <img src="/images/DownloadOnAppStore.svg" />
      </a>
    </div>
  </div>

  <div class="explainBlock">
    <h1>More Details</h1>
    <ul>
      <li>
        <a href="https://ikep.hatenablog.com/entry/2018/08/01/231802"
          >My blog</a
        >
      </li>
    </ul>
  </div>
</template>

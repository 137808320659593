<template>
  <div class="explainBlock">
    <h1>Legend Creature</h1>
    <div id="twoColImg">
      <li>
        <ul>
          <img src="/images/legend_creature.jpg" />
        </ul>
        <ul>
          <img src="/images/legend_creature2.jpg" />
        </ul>
        <ul>
          <img src="/images/legend_creature3.jpg" />
        </ul>
        <ul>
          <img src="/images/legend_creature4.jpg" />
        </ul>
        <ul>
          <img src="/images/legend_creature5.jpg" />
        </ul>
      </li>
    </div>
  </div>

  <div class="explainBlock">
    <h1>What's this?</h1>
    <div>
      {{ $t("message.LegendCreatureWhat1") }}
    </div>
    <div>
      {{ $t("message.LegendCreatureWhat2") }}
    </div>
    <br />
    <div>
      {{ $t("message.LegendCreatureWhat3") }}
    </div>
  </div>

  <div class="explainBlock">
    <h1>Demo</h1>
    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/chAifFdj8c0"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>

  <div class="explainBlock">
    <h1>Development</h1>
    <div>Programming Language</div>
    <ul>
      <li>C#</li>
    </ul>
    <div>Software</div>
    <ul>
      <li>Unity</li>
    </ul>
  </div>

  <div class="explainBlock">
    <h1>Source Code</h1>
    <a href="https://github.com/creativeIKEP/legendCreature" target="_blank">
      GitHub
    </a>
  </div>

  <div class="explainBlock">
    <h1>Created Day</h1>
    <div>2018/03</div>
  </div>

  <div class="explainBlock">
    <h1>More Details</h1>
    <ul>
      <li>
        <a
          href="https://ikep.hatenablog.com/entry/2018/07/06/220638"
          target="_blank"
          >My blog</a
        >
      </li>
    </ul>
  </div>
</template>

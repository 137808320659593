import axios from "axios";

type product = {
  imagePath: string;
  nameKey: string;
  url: string;
};

export const productsList = () => {
  const list: product[] = [
    {
      imagePath:
        "https://user-images.githubusercontent.com/34697515/243058841-e07ced4d-5d55-469f-bac0-0a88cdf6a90e.gif",
      nameKey: "UniCamEx",
      url: "https://github.com/creativeIKEP/UniCamEx",
    },
    {
      imagePath:
        "https://user-images.githubusercontent.com/34697515/211329841-83ea990d-a597-44f1-a252-efaca8a38cb7.gif",
      nameKey: "HolisticMotionCapture",
      url: "https://github.com/creativeIKEP/HolisticMotionCapture",
    },
    {
      imagePath: "/images/mirror.jpg",
      nameKey: "mirror",
      url: "/products/mirror",
    },
    {
      imagePath: "/images/mimiclear.jpg",
      nameKey: "mimiclear",
      url: "/products/mimiclear",
    },
    {
      imagePath:
        "https://user-images.githubusercontent.com/34697515/192131544-97d0aedb-bd4b-477c-a367-4c7f22f8f7cb.gif",
      nameKey: "HolisticBarracuda",
      url: "https://github.com/creativeIKEP/HolisticBarracuda",
    },
    {
      imagePath:
        "https://user-images.githubusercontent.com/34697515/153871000-da4d9a9a-db93-49f4-8ebf-28e20fe135fa.gif",
      nameKey: "BlazePoseBarracuda",
      url: "https://github.com/creativeIKEP/BlazePoseBarracuda",
    },
    {
      imagePath:
        "https://user-images.githubusercontent.com/34697515/125967592-8dd291e3-3f54-4f26-8086-fcaad6c70714.gif",
      nameKey: "VirtualBackgroundCamera",
      url: "https://github.com/creativeIKEP/VirtualBackgroundCamera",
    },
    {
      imagePath:
        "https://user-images.githubusercontent.com/34697515/135959250-ae16c070-54ea-42d6-af2c-b6fd7d773f41.gif",
      nameKey: "SelfieSegmentationBarracuda",
      url: "https://github.com/creativeIKEP/SelfieSegmentationBarracuda",
    },
    {
      imagePath: "/images/Treadraw.jpg",
      nameKey: "Treadraw",
      url: "/products/Treadraw",
    },
    {
      imagePath: "/images/i_am_man.jpg",
      nameKey: "I_AM_MAN",
      url: "/products/i_am_man",
    },
    {
      imagePath: "/images/GPUBasedVRPaint.jpg",
      nameKey: "GpuBasedVrPaint",
      url: "/products/GPUBasedVRPaint",
    },
    {
      imagePath: "/images/HoloPresenter.png",
      nameKey: "HoloPresenter",
      url: "/products/HoloPresenter",
    },
    {
      imagePath: "/images/shineKaguya.jpg",
      nameKey: "shineKaguya",
      url: "/products/shineKaguya",
    },
    {
      imagePath: "/images/handMogura.PNG",
      nameKey: "HandTrackingGame",
      url: "/products/handMogura",
    },
    {
      imagePath: "/images/legend_creature.jpg",
      nameKey: "LegendCreature",
      url: "/products/legend_creature",
    },
    {
      imagePath: "/images/DX_GUN_GALE1.png",
      nameKey: "DxGunGale",
      url: "/products/dx_gun_gale",
    },
    {
      imagePath: "/images/ikepCube.jpg",
      nameKey: "IKEPcube",
      url: "/products/ikepCube",
    },
    {
      imagePath: "/images/superARCart.png",
      nameKey: "SuperArCart",
      url: "/products/superARCart",
    },
    {
      imagePath: "/images/tamaireMoon1.png",
      nameKey: "TamaireMoon",
      url: "/products/TamaireMoon",
    },
    {
      imagePath: "/images/sandada.jpg",
      nameKey: "3DCGModeling",
      url: "https://ikep.hatenablog.com/entry/2018/06/15/155743",
    },
    {
      imagePath: "/images/jointAngle.png",
      nameKey: "RgbCameraJointEstimation",
      url: "https://ikep.hatenablog.com/entry/2018/08/05/152206",
    },
    {
      imagePath: "/images/GoogleHomeApp.JPG",
      nameKey: "GoogleHomeApp",
      url: "/products/GoogleHomeApp",
    },
    {
      imagePath: "/images/EyeBreakTimer.png",
      nameKey: "EyeBreakTimer",
      url: "/products/EyeBreakTimer",
    },
    {
      imagePath: "/images/BinaryCalcurator.png",
      nameKey: "BinaryCalcurator",
      url: "/products/BinaryCalcurator",
    },
    {
      imagePath: "/images/cameraSystem.jpg",
      nameKey: "CameraSystem",
      url: "/products/cameraSystem",
    },
    {
      imagePath: "/images/macBookVFX.png",
      nameKey: "MacVfx",
      url: "https://ikep.hatenablog.com/entry/2018/06/30/002756",
    },
  ];

  return list;
};

export type News = {
  date: string;
  jaText: string;
  enText: string;
  link: string;
  isBlank: boolean;
};

export const newsList = () => {
  const newsList = axios
    .get("/api/news")
    .then((res) => {
      if (res.data.ok) {
        return res.data.payload as News[];
      }
      return [] as News[];
    })
    .catch((e) => {
      console.error(e);
      return [] as News[];
    });
  return newsList;
};

import { createI18n } from "vue-i18n";
import jaMessage from "./assets/i18n/ja.json";
import enMessage from "./assets/i18n/en.json";

const messages = {
  ja: {
    message: jaMessage,
  },
  en: {
    message: enMessage,
  },
};

const i18n = createI18n({
  locale: "ja",
  messages: messages,
});

export default i18n;

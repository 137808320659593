
import { defineComponent } from "vue";
import routerPath from "../routerPath";
import { newsList, News } from "../viewDatas";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    displayNewsCount: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      newsListData: [] as News[],
      isEnglishPage: false,
    };
  },
  mounted() {
    newsList().then((list) => {
      this.newsListData = list;
    });

    if (location.pathname.match("^/en")) {
      this.isEnglishPage = true;
    } else {
      this.isEnglishPage = false;
    }
  },
  updated() {
    // mounted()時点ではnewsデータがなく表示位置がおかしいので、表示されてから移動させる
    if (window.location.hash !== "#pastNews") {
      return;
    }
    const element = document.getElementById("pastNews");
    if (!element) return;
    element.scrollIntoView();
  },
  methods: {
    isExternalUrl: routerPath.isExternalUrl,
    routerPath: routerPath.routerPath,
  },
});

<template>
  <div class="explainBlock">
    <h1>{{ $t("message.CameraSystem") }}</h1>
    <img src="/images/cameraSystem.jpg" />
  </div>

  <div class="explainBlock">
    <h1>What's this?</h1>
    <div>
      {{ $t("message.CameraSystemWhat1") }}
    </div>
    <div>
      {{ $t("message.CameraSystemWhat2") }}
    </div>
    <div>
      {{ $t("message.CameraSystemWhat3") }}
    </div>
    <div>
      {{ $t("message.CameraSystemWhat4") }}
    </div>
    <div>{{ $t("message.CameraSystemWhat5") }}</div>
    <div>
      {{ $t("message.CameraSystemWhat6") }}
    </div>
  </div>

  <div class="explainBlock">
    <h1>Development</h1>
    <div>Programming Language</div>
    <ul>
      <li>HTML/CSS</li>
      <li>JavaScript</li>
    </ul>
    <div>Platform&Others</div>
    <ul>
      <li>Google Apps Script</li>
    </ul>
  </div>

  <div class="explainBlock">
    <h1>Source Code</h1>
    <a href="https://github.com/creativeIKEP/cameraSystem" target="_blank">
      GitHub
    </a>
  </div>

  <div class="explainBlock">
    <h1>Created Day</h1>
    <div>2017/12</div>
  </div>

  <div class="explainBlock">
    <h1>More Details</h1>
    <ul>
      <li>
        <a href="https://ikep.hatenablog.com/entry/2018/05/16/234359"
          >My blog</a
        >
      </li>
    </ul>
  </div>
</template>

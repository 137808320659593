<template>
  <div class="explainBlock">
    <h1>mimiclear</h1>
    <img src="/images/mimiclear.jpg" />
  </div>

  <div class="explainBlock">
    <h1>What's this?</h1>
    <div>
      {{ $t("message.mimiclearWhat1") }}
    </div>
    <div>
      {{ $t("message.mimiclearWhat2") }}
    </div>
    <div>
      {{ $t("message.mimiclearWhat3") }}
    </div>
  </div>

  <div class="explainBlock">
    <h1>Video</h1>
    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/LUQycGa1J8U"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>

  <div class="explainBlock">
    <h1>Development</h1>
    <div>Programming Language</div>
    <ul>
      <li>C#</li>
      <li>Python</li>
    </ul>
    <div>Software</div>
    <ul>
      <li>Unity</li>
      <li>Blender</li>
    </ul>
    <div>Platform&Others</div>
    <ul>
      <li>Kinect v2</li>
    </ul>
  </div>

  <div class="explainBlock">
    <h1>Prize</h1>
    <ul>
      <li>
        Interverse Virtual Reality Challenge 2021 (IVRC2021)
        <a href="https://ivrc.net/2021/release3/" target="_blank">
          {{ $t("message.teamLabPrize") }}
        </a>
      </li>
    </ul>
  </div>

  <div class="explainBlock">
    <h1>Exhibition</h1>
    <ul>
      <li>
        <a href="https://ivrc.net/2021/" target="_blank">
          Interverse Virtual Reality Challenge 2021 (IVRC2021)
        </a>
      </li>
    </ul>
  </div>

  <div class="explainBlock">
    <h1>Created Day</h1>
    <div>2021/09</div>
  </div>

  <div class="explainBlock">
    <h1>More Details</h1>
    <ul>
      <li>
        <a href="http://ivrc.net/archive/mimiclear2021/" target="_blank"
          >IVRC Archive</a
        >
      </li>
      <li>
        <a
          href="http://conference.vrsj.org/ac2021/program/doc/IVRC-14.pdf"
          target="_blank"
          >Proceeding paper</a
        >
      </li>
      <li>
        <a href="https://ikep.hatenablog.com/entry/2021/12/22/175800"
          >My blog</a
        >
      </li>
      <li>
        <a href="https://ikep.hatenablog.com/entry/2022/01/04/225521">
          {{ $t("message.mimiclearPipilineBlog") }}
        </a>
      </li>
    </ul>
  </div>
</template>

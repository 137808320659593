<template>
  <div class="explain">
    <component v-bind:is="currentComponent"></component>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import BinaryCalcurator from "./products/BinaryCalcurator.vue";
import cameraSystem from "./products/cameraSystem.vue";
import dx_gun_gale from "./products/dx_gun_gale.vue";
import EyeBreakTimer from "./products/EyeBreakTimer.vue";
import GoogleHomeApp from "./products/GoogleHomeApp.vue";
import GPUBasedVRPaint from "./products/GPUBasedVRPaint.vue";
import handMogura from "./products/handMogura.vue";
import HoloPresenter from "./products/HoloPresenter.vue";
import i_am_man from "./products/i_am_man.vue";
import ikepCube from "./products/ikepCube.vue";
import legend_creature from "./products/legend_creature.vue";
import mimiclear from "./products/mimiclear.vue";
import mirror from "./products/mirror.vue";
import shineKaguya from "./products/shineKaguya.vue";
import superARCart from "./products/superARCart.vue";
import TamaireMoon from "./products/TamaireMoon.vue";
import Treadraw from "./products/Treadraw.vue";

export default defineComponent({
  components: {
    BinaryCalcurator,
    cameraSystem,
    dx_gun_gale,
    EyeBreakTimer,
    GoogleHomeApp,
    GPUBasedVRPaint,
    handMogura,
    HoloPresenter,
    i_am_man,
    ikepCube,
    legend_creature,
    mimiclear,
    mirror,
    shineKaguya,
    superARCart,
    TamaireMoon,
    Treadraw,
  },
  data() {
    return {
      currentComponent: "",
    };
  },
  mounted() {
    // 対象のproductに変更
    this.currentComponent = this.$route.params.product_name;

    // このファイルにimportされていないcomponentsだったら404にする
    import("./products").then((targetComponents) => {
      if (
        targetComponents.default.components[this.currentComponent] === undefined
      ) {
        this.$root.isNotFound = true;
      }
    });
  },
});
</script>

<style>
.explain {
  color: white;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 10%;
}

.explainBlock {
  margin-bottom: 8%;
}

img {
  width: 100%;
}

a {
  color: white;
}

#twoColImg li {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  align-items: center;
}

#twoColImg li ul {
  width: 45%;
}

.appDownloadButton a img {
  width: 15%;
}

@media screen and (max-aspect-ratio: 16/10) {
  .explain {
    font-size: 120%;
    color: white;
    margin-left: 10%;
    margin-right: 10%;
  }
  #twoColImg li {
    text-align: center;
  }
  #twoColImg li ul {
    padding: 0;
    width: 100%;
  }
  .appDownloadButton a img {
    width: 20%;
  }
}

@media screen and (max-aspect-ratio: 5/7) {
  .explain {
    font-size: 150%;
    color: white;
    margin-left: 10%;
    margin-right: 10%;
  }
  #twoColImg li {
    text-align: center;
  }
  #twoColImg li ul {
    padding: 0;
    width: 100%;
  }
  .appDownloadButton a img {
    width: 30%;
  }
}
</style>

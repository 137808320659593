
import { defineComponent } from "vue";
import routerPath from "../routerPath";
import News from "../components/News.vue";
import Product from "../components/Product.vue";

export default defineComponent({
  components: {
    News,
    Product,
  },
  props: {
    isBackgroundMove: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    routerPath: routerPath.routerPath,
  },
});

<template>
  <div class="explainBlock">
    <h1>{{ $t("message.TamaireMoon") }}</h1>
    <div id="twoColImg">
      <li>
        <ul>
          <img src="/images/tamaireMoon1.png" />
        </ul>
        <ul>
          <img src="/images/tamaireMoon2.png" />
        </ul>
      </li>
    </div>
  </div>

  <div class="explainBlock">
    <h1>What's this?</h1>
    <div>
      {{ $t("message.TamaireMoonWhat1") }}
    </div>
    <div>{{ $t("message.TamaireMoonWhat2") }}</div>
    <div>
      {{ $t("message.TamaireMoonWhat3") }}
    </div>
    <div>
      {{ $t("message.TamaireMoonWhat4") }}
    </div>
    <div>{{ $t("message.TamaireMoonWhat5") }}</div>
    <div>{{ $t("message.TamaireMoonWhat6") }}</div>
  </div>

  <div class="explainBlock">
    <h1>Demo</h1>
    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/PQ256AnEtqs"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>

  <div class="explainBlock">
    <h1>Development</h1>
    <div>Programming Language</div>
    <ul>
      <li>C#</li>
    </ul>
    <div>SoftWare</div>
    <ul>
      <li>Unity</li>
    </ul>
    <div>Platform&Others</div>
    <ul>
      <li>Daydream(Google VR)</li>
    </ul>
  </div>

  <div class="explainBlock">
    <h1>Source Code</h1>
    <a href="https://github.com/creativeIKEP/TamaireMoon" target="_blank">
      GitHub
    </a>
  </div>

  <div class="explainBlock">
    <h1>Exhibition</h1>
    <ul>
      <li>
        <a href="https://gree-xr-hackathon.com" target="_blank">
          {{ $t("message.LunarSportsVrHackathon") }}
        </a>
      </li>
    </ul>
  </div>

  <div class="explainBlock">
    <h1>Created Day</h1>
    <div>2018/05</div>
  </div>
</template>

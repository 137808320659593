
import { defineComponent } from "vue";
import i18n from "../i18n";
import routerPath from "../routerPath";

export default defineComponent({
  props: {
    isBackgroundMove: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      headerLinks: [
        { link: "/", text: "Home" },
        { link: "/about", text: "About" },
        { link: "/product", text: "Product" },
        { link: "https://ikep.hatenablog.com/archive", text: "Blog" },
      ],
      languageSelects: [
        { value: "ja", text: "日本語" },
        { value: "en", text: "English" },
      ],
      backMoveSelects: [
        { value: "on", text: "ON" },
        { value: "off", text: "OFF" },
      ],
    };
  },
  computed: {
    SelectedLanguage(): string {
      const pathname = location.pathname;
      return pathname.match("^/en")
        ? this.languageSelects[1].value
        : this.languageSelects[0].value;
    },
    SelectedBackgroundMove(): string {
      return this.isBackgroundMove
        ? this.backMoveSelects[0].value
        : this.backMoveSelects[1].value;
    },
  },
  mounted() {
    i18n.global.locale = location.pathname.match("^/en") ? "en" : "ja";
  },
  methods: {
    isExternalUrl: routerPath.isExternalUrl,
    routerPath: routerPath.routerPath,
    ChangeLanguage(e: Event) {
      const langPathMap = new Map<string, string>([
        ["ja", ""],
        ["en", "/en"],
      ]);
      const selectedHtmlElement = e.target as HTMLSelectElement;
      const selectedMode = selectedHtmlElement.value;
      const langPath = langPathMap.get(selectedMode);

      let nowPathname = location.pathname;
      if (nowPathname === "/en") {
        nowPathname = "/";
      }
      if (nowPathname.match("^/en")) {
        nowPathname = nowPathname.replace("/en", "");
      }
      // 言語切り替えでrouterPath.tsのrouterPathを使えないので直接遷移
      location.href = `${langPath}${nowPathname}${location.search}`;

      i18n.global.locale = selectedMode as "ja" | "en";
    },
    ChangeBackgroundMove(e: Event) {
      const selectedHtmlElement = e.target as HTMLSelectElement;
      const selectedMode = selectedHtmlElement.value;
      // WebGL再レンダリングを狙う
      location.href = `${location.pathname}?back=${selectedMode}`;
    },
  },
});

<template>
  <div class="explainBlock">
    <h1>GPU Based VR Paint</h1>
    <div id="twoColImg">
      <li>
        <ul>
          <img src="/images/GPUBasedVRPaint.jpg" />
        </ul>
        <ul>
          <img src="/images/GPUBasedVRPaint1.jpg" />
        </ul>
        <ul>
          <img src="/images/GPUBasedVRPaint2.jpg" />
        </ul>
        <ul>
          <img src="/images/GPUBasedVRPaint3.jpg" />
        </ul>
        <ul>
          <img src="/images/GPUBasedVRPaint4.jpg" />
        </ul>
        <ul>
          <img src="/images/GPUBasedVRPaint5.jpg" />
        </ul>
      </li>
    </div>

    <div class="explainBlock">
      <h1>What's this?</h1>
      <div>
        <a href="https://www.team-lab.com/" target="_blank">
          {{ $t("message.teamLab") }}
        </a>
        {{ $t("message.GpuBasedVrPaintWhat1") }}
      </div>
      <div>
        {{ $t("message.GpuBasedVrPaintWhat2") }}
      </div>
    </div>

    <div class="explainBlock">
      <h1>Demo</h1>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/6sFuOqQfx1U"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <br />
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/avoS8pCKpvk"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <br />
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/MWPQ3X8cXmA"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>

    <div class="explainBlock">
      <h1>Development</h1>
      <div>Programming Language</div>
      <ul>
        <li>C#</li>
        <li>Unity ShaderLab</li>
        <li>Cg/HLSL</li>
      </ul>
      <div>Software</div>
      <ul>
        <li>Unity</li>
      </ul>
      <div>Platform&Others</div>
      <ul>
        <li>HTC Vive</li>
        <li>Oculus Quest2 (Oculus Link)</li>
      </ul>
    </div>

    <div class="explainBlock">
      <h1>Source Code</h1>
      <a href="https://github.com/creativeIKEP/GPUBasedVRPaint" target="_blank">
        GitHub
      </a>
    </div>

    <div class="explainBlock">
      <h1>Created Day</h1>
      <div>2019/9</div>
    </div>

    <div class="explainBlock">
      <h1>More Details</h1>
      <ul>
        <li>
          <a href="https://ikep.hatenablog.com/entry/2019/11/11/144217"
            >My blog</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

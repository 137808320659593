<template>
  <div class="explainBlock">
    <h1>mirror ⇄ ɿoɿɿim</h1>
    <div id="twoColImg">
      <li>
        <ul>
          <img src="/images/mirror.jpg" />
        </ul>
        <ul>
          <img src="/images/mirror2.jpg" />
        </ul>
        <ul>
          <img src="/images/mirror3.jpg" />
        </ul>
        <ul>
          <img src="/images/mirror4.jpg" />
        </ul>
        <ul>
          <img src="/images/mirror5.jpg" />
        </ul>
        <ul>
          <img src="/images/mirror6.jpg" />
        </ul>
      </li>
    </div>
  </div>

  <div class="explainBlock">
    <h1>What's this?</h1>
    <div>
      {{ $t("message.mirrorWhat1") }}
    </div>
    <div>
      {{ $t("message.mirrorWhat2") }}
    </div>
    <div>
      {{ $t("message.mirrorWhat3") }}
    </div>
  </div>

  <div class="explainBlock">
    <h1>Video</h1>
    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/0XZp_P01LM0"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>

  <div class="explainBlock">
    <h1>Development</h1>
    <div>Programming Language</div>
    <ul>
      <li>C#</li>
      <li>Python</li>
    </ul>
    <div>Software</div>
    <ul>
      <li>Unity</li>
      <li>Blender</li>
    </ul>
    <div>Platform&Others</div>
    <ul>
      <li>Arduino</li>
      <li>Windows Mixed Reality</li>
    </ul>
  </div>

  <div class="explainBlock">
    <h1>Prize</h1>
    <ul>
      <li>
        Interverse Virtual Reality Challenge 2021 (IVRC2021)
        <a href="https://ivrc.net/2021/release5/" target="_blank">
          {{ $t("message.VrSocietyAward") }}
        </a>
      </li>
      <li>
        Interverse Virtual Reality Challenge 2021 (IVRC2021)
        <a href="https://ivrc.net/2021/release3/" target="_blank">
          {{ $t("message.UnityPrize") }}
        </a>
      </li>
    </ul>
  </div>

  <div class="explainBlock">
    <h1>Exhibition</h1>
    <ul>
      <li>
        <a href="https://ivrc.net/2021/" target="_blank"
          >Interverse Virtual Reality Challenge 2021 (IVRC2021)</a
        >
      </li>
    </ul>
  </div>

  <div class="explainBlock">
    <h1>Created Day</h1>
    <div>2021/09</div>
  </div>

  <div class="explainBlock">
    <h1>More Details</h1>
    <ul>
      <li>
        <a
          href="http://ivrc.net/archive/mirror-%e2%87%84-%c9%bfo%c9%bf%c9%bfim2021/"
          target="_blank"
          >IVRC Archive</a
        >
      </li>
      <li>
        <a
          href="http://conference.vrsj.org/ac2021/program/doc/IVRC-15.pdf"
          target="_blank"
          >Proceeding paper</a
        >
      </li>
      <li>
        <a href="https://ikep.hatenablog.com/entry/2021/12/22/175800"
          >My blog</a
        >
      </li>
    </ul>
  </div>
</template>
